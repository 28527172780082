.sub-container {
  display: flex;
  justify-content: space-between;

  /* max-width: 100vw; */
  /* min-width: 100vw; */
  /* margin: 1x; */
}
.sub-container > *:first-child {
  margin-left: -10px;
}

.sub-container > *:last-child {
  margin-right: -10px;
}
.horizontal-scroll-table {
  max-width: 100vw;
  /* min-width: 100vw; */
  overflow-y: auto;
  max-height: 72vh;
  min-height: 72vh;

  z-index: 0;
}
.dashboard-main-container {
  max-height: 89vh;
  overflow-y: hidden;
  margin-top: -6px;
}

.custom-height .multi-select-style {
  height: 30px !important; /* Fix the height */
  line-height: normal; /* Reset line-height to avoid conflicts */
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Vertically center the text */
  padding: 0 8px; /* Add some padding if needed */
  box-sizing: border-box; /* Ensure padding doesn't affect height */
}

.custom-height .dropdown-container {
  height: 30px !important; /* Fix dropdown height */
  display: flex; /* Use flexbox for dropdown items as well */
  align-items: center; /* Vertically center the dropdown text */
}

.dashboard-td-font {
  font-size: 12px;
}
.dashboard-thead-font {
  font-size: 12px;
}

.dashboard-card {
  width: 12%;
  height: fit-content;
  /* margin-top: 15px; */
  max-height: 60px;
  padding-top: 8px;
  margin: 3px;
  /* padding: 0px; */
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.2s;
  cursor: pointer;
  background-image: linear-gradient(to right, #23b2d5, #219bcc);
  background-color: #fff;
  margin-top: 40px;
  min-width: 100%;
  min-height: 48px;
  text-align: center;
}
.scroll-effect-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  /* z-index: 2; */
  /* padding: 5px; */
  /* background-color: #cae8ca; */
  /* border: 2px solid #4CAF50; */
}

.multi-select-style {
  z-index: 10;
}

tr {
  border: 1px solid #ddd;
  color: black;
  /* padding: 8px; */
}

tr:nth-child(even) {
  background-color: #fff;
  color: #000;
}
tr:nth-child(odd) {
  background-color: #fff;
  color: #000;
}

tr:hover {
  background-color: #ddd;
}

th {
  text-align: center;
  /* padding: 12px; */
  font-weight: bolder;
}

td {
  text-align: center;
  color: #000;
  /* padding: 12px; */
}

.heading {
  color: #fff;
  font-weight: 800;
  font-size: 20px;
}

/* .table-container{
    margin: 10px;
    border-radius: 50vh;
    border-width: 10px;
  } */

.table-container {
  margin: 0 auto; /* Center the table horizontally */
  max-width: 100%; /* Ensure table width doesn't exceed container */
}

table {
  margin: 0 auto; /* Center the table within its parent */
  margin-bottom: 20px; /* Bottom margin for spacing */
  /* Additional styling as needed */
}
