.category-input-container{
     /* border-radius: 10px;
    width: 60%;
  
    display: flex;
    background-color: white;  */
    /* border-radius: 1rem; */
    /* background: linear-gradient(45deg, #c5c5c5 0%, #ffffff 100%); */
    /* padding: 0.3rem;
    margin-left: 25px;
    justify-content: space-between;  */
 
}
 
.category-inner_div{
  justify-content: flex-end;
  display: flex;
  /* margin-bottom: 1%; */
}
 
  @media (max-width: 500px) {
    .category-input-container {
      flex-direction: column;
    }
    }
 
 
  tr {
    border: 1px solid #ddd;
    color: black;
    /* padding: 8px; */
  }
   
  tr:nth-child(even) {
    background-color: #fff;
    color: #000;
    background-color: #f6f6f6;
  }
  tr:nth-child(odd) {
    background-color: #fff;
    background-color: #e9e9e9;
    color: #000;
  }
   
   
  tr:hover{
    background-color: #ddd;
   
  }
   
  th{
    /* text-align: center; */
    background-color: #219bcc;
    color: white;
    /* padding: 12px; */
   
  }
  td{
    /* text-align: center; */
    color: #000;
    /* padding: 12px; */
   
  }
 
  .category-table{

  }