/* .profile-img{
    width:100px;
    height: 100px;
    border: 1px solid black;
    border-radius: 60px;
    cursor: pointer;
    
} */
.profile-img {
    width:100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover; /* This will maintain aspect ratio without stretching */
  }
.profile-img-wrapper{
    display: flex;
    justify-content: center;
    
}
.profile-main-container{
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 6%;
  
    margin-bottom: 6%;
    box-shadow: 4px 4px 6px 4px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      overflow: hidden;
      transition: transform 0.3s, box-shadow 0.3s;

}
.profile-main-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
  .loading-icon{
    display:flex;
    justify-content: center;
  }
  .camera {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  video {
    width: 300px;
    height: 300px;
    object-fit: cover;
    background: black;
  }
  


  .imageWrapper{
    width: '30%';
    height: '70%';
    background-color: '#fff';
    border-radius: 10;
    overflow: 'hidden';
    justify-content: 'center';
    align-items: 'center';
  }