/* .custom-datepicker.react-datepicker{
    width:300px !important;
    height:400px !important;
    border: 2px solid #ced4da;
    border-radius: 60px;
    padding: 10px;
  
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
} */
.custom-datepicker {
    height: 32px; /* Reduce height */
    font-size: 14px; /* Adjust font size */
    padding: 5px 10px; /* Reduce padding */
    width: 260px !important; /* Set custom width */
    max-width: 100% !important; /* Prevent it from exceeding the container */
  }
  .custom-remarks {
    height: 20px !important; /* Reduce height */
    font-size: 14px; /* Adjust font size */
    padding: 5px 10px; /* Reduce padding */
    width: 260px !important; /* Set custom width */
    max-width: 100% !important; /* Prevent it from exceeding the container */
  }
  .table-client-visit{
    margin-left: -20px;

  }