.employee-input-container {
  border-radius: 10px;
  width: 98%;
  height: 64vh;
  background-color: white;
  margin-top: 1%;
  justify-content: space-between;
  margin-left: 1%;
}

@media (max-width: 500px) {
  .employee-input-container {
    flex-direction: column;
  }
}

.employee-inner_div {
  justify-content: flex-end;
  display: flex;
}

.employee-button-style {
  color: #fff;
  font-weight: 500;
  padding: 10px 30px;
  box-shadow: inset -2px -2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  border-color: #fff;
}

.employee-button-style:hover {
  transform: scale(1.1);
  animation-duration: 0.2s;
  transition: ease;
}

@media (max-width: 500px) {
  .employee-input-container {
    flex-direction: column;
  }
}

tr {
  border: 1px solid #ddd;
  color: black;
  /* padding: 8px; */
}

tr:nth-child(even) {
  background-color: #fff;
  color: #000;
  background-color: #f6f6f6;
}

tr:nth-child(odd) {
  background-color: #fff;
  background-color: #e9e9e9;
  color: #000;
}

tr:hover {
  background-color: #ddd;
}

.th {
  /* padding-top: 12px;
        padding-bottom: 12px; */
  text-align: left;
  background-color: #2d344b;
  color: white;
  /* padding: 12px; */
}

td {
  text-align: left;
  color: #000;
  /* padding: 12px; */
}

.employee-table {
  margin-top: 5%;
}
