.parent-container {
  margin: 1%;
}

.child-container-2 {
  display: flex;
  background-color: #fff;
  margin-top: 1%;
  min-height: 36.5vh;
  max-height: fit-content;
}

.ticket-details-headers{
   background-image: linear-gradient(to right,#219bcc ,#23b2d5);
}

.left-child-container-2 {
  width: 65%;
}

.right-child-container-2 {
  width: 35%;
  padding: 1%;
}

.left-child-container {
  width: 60%;
}

.form-details {
  align-items: start;
  display: flex;
}

.typo-style {
  /* color: "#595959";
  padding: 1px;
  margin-left: 6px;
  font-size: 14px; */
    /* padding: 10px; */


  
  /* margin-left: 100px; */
}

.typo-label-box {
  /* color: "#595959"; */
  font-weight: 600;
  /* font-size: 14px; */
  /* padding: 1px; */
  /* width: 200; */
  max-width: 30%;
  min-width: 30%;
  color:#3f5078;
  font-size: 14px;

                
}

@media only screen and (min-width: 992px) {
}

@media (max-width: 768px) {
  .form-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .typo-style {
    /* max-width: 45%;
    min-width: 45%;
    text-align: start;
    word-wrap: break-word; */
  }
  .typo-value-field {
    max-width: 130px;
    word-wrap: break-word;
  }
}

@media (max-width: 320px) {
  .form-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .typo-style {
    /* max-width: 45%;
    min-width: 45%;
    text-align: start;
    word-wrap: break-word; */
  }
  .typo-value-field {
    max-width: 130px;
    word-wrap: break-word;
  }
}

.inner_div {
  justify-content: flex-end;
  display: flex;
  margin-bottom: 50px;
  margin-top: 15px;
}

.button {
  color: #fff;
  font-weight: 500;
  /* padding: 5px; */
  box-shadow: inset -2px -2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  border-color: #fff;
}

.button:hover {
  /* transform: scale(1.1); */
  /* transition: 0.3s all; */
  /* animation-duration: 0.2s; */
   transform: translateY(-2px);
  
  transition: ease;
}

.approver-buttons {
  appearance: button;
  background-color: #1899d6;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 20px;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 13px 19px;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transform: translateZ(0);
  transition: filter 0.2s;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.approver-buttons:after {
  background-clip: padding-box;
  background-color: #1cb0f6;
  border: solid transparent;
  border-radius: 16px;
  border-width: 0 0 4px;
  bottom: -4px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.approver-buttons:main,
.approver-buttons:focus {
  user-select: auto;
}

.approver-buttons:hover:not(:disabled) {
  filter: brightness(1.1);
}

.approver-buttons:disabled {
  cursor: auto;
}

.approver-buttons:active:after {
  border-width: 0 0 0px;
}

.approver-buttons:active {
  padding-bottom: 10px;
}
